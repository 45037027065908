
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[...full_slug]",
      function () {
        return require("private-next-pages/[...full_slug].js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[...full_slug]"])
      });
    }
  