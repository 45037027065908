import React, { useEffect } from "react";
import Sections from "components/sections/sections";
import Seo from "components/Seo";
import ErrorPage from "next/error";
import { useRouter } from "next/router";
import { fetchAPI, getGlobalData, getPageData } from "../utils/api";
import { getLocalizedPaths } from "../utils/localize";
import { Mixpanel } from "utils/mixpanel";
import { addLeadingSlash, removeLeadingSlash } from "utils/utils";

const DynamicPage = ({
  sections,
  metadata,
  preview,
  global,
  // pageContext, // not in use untill internationalisation
  pageData,
}) => {
  const router = useRouter();

  // Check if required data was provided
  if (!router.isFallback && !sections?.length) {
    return <ErrorPage statusCode={404} />;
  }

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>;
  }

  // Merge default site SEO settings with page specific SEO settings
  if (!metadata?.shareImage?.data) {
    delete metadata.shareImage;
  }
  const metadataWithDefaults = {
    ...global.attributes.metadata,
    ...metadata,
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const trackPageHit = () => {
      Mixpanel.track(pageData.title);
    };
    trackPageHit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* add meta tags for seo */}
      <Seo metadata={metadataWithDefaults} />
      {/* Display content Sections */}
      <Sections sections={JSON.parse(sections)} preview={preview} />
    </>
  );
};

export async function getStaticPaths(context) {
  // Get all pages from Strapi
  const pages = await context.locales.reduce(
    async (currentPagesPromise, locale) => {
      const currentPages = await currentPagesPromise;
      const localePages = await fetchAPI("/pages", {
        locale,
        fields: ["full_slug", "locale"],
      });
      return [...currentPages, ...localePages.data];
    },

    // eslint-disable-next-line no-undef
    Promise.resolve([])
  );

  const filterOutResourcePages = pages.filter((page) => {
    const { full_slug } = page.attributes;
    const containResources = full_slug.includes("resources");
    const containCareerPage = full_slug.includes("career-page");
    if (containResources || containCareerPage) return false;
    else true;
  });

  const paths = filterOutResourcePages.map((page) => {
    const { full_slug, locale } = page.attributes;
    // Decompose the slug that was saved in Strapi
    const slugArray = !full_slug
      ? false
      : removeLeadingSlash(full_slug).split("/");
    return {
      params: { full_slug: slugArray },
      // Specify the locale to render
      locale,
    };
  });

  return { paths, fallback: "blocking" };
}

export async function getStaticProps(context) {
  const { params, locale, locales, defaultLocale, preview = null } = context;

  const globalLocale = await getGlobalData(locale);
  // Fetch pages. Include drafts if preview mode is on
  const pageData = await getPageData({
    full_slug: addLeadingSlash(params.full_slug.join("/")),
    locale,
    preview,
  });

  if (pageData == null) {
    // Giving the page no props will trigger a 404 page
    return { props: {} };
  }

  // We have the required page data, pass it to the page component
  const {
    contentSections,
    metadata,
    localizations,
    slug,
    full_slug,
    ...pageExtraData
  } = pageData.attributes;

  const pageContext = {
    locale,
    locales,
    defaultLocale,
    slug,
    localizations,
    full_slug,
  };

  const localizedPaths = getLocalizedPaths(pageContext);

  return {
    props: {
      preview,
      sections: JSON.stringify(contentSections),
      metadata,
      global: globalLocale,
      pageData: { ...pageExtraData },
      pageContext: {
        ...pageContext,
        localizedPaths,
      },
    },
    revalidate: 30,
  };
}

export default DynamicPage;
